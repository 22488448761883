import { useBattlepassStore } from './battlepass.store';
import { useBattlepassLevelsStore } from './battlepassLevels.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import type { IBattlepassTask } from '~/features/mainPage/Types/battlepass/client.types';

/* Хранилище доп.заданий батлпасса */
export const useBattlepassTasksStore = defineStore('mainPage/battlepass/tasks', () => {
  /* Import */
  /* Хранилище батлпасса */
  const battlepassStore = useBattlepassStore();
  const battlepassLevelsStore = useBattlepassLevelsStore();
  const authStore = useAuthStore();

  /* Getters */
  /* Доступные доп.задания */
  const shownTasks = computed<IBattlepassTask[]>(() => {
    if (!authStore.isAuth) return [];
    if (!battlepassLevelsStore.activeLevel) return [];

    const toLevel = battlepassLevelsStore.activeLevel.level;
    return battlepassStore.tasks.filter((task) => {
      if (task.level > toLevel) return false;
      if (!task.progressData) return true;
      return task.progressData.current < task.progressData.end;
    });
  });

  return {
    shownTasks,
  };
});
