export enum EUseDragSliderDirections {
  NEXT = 'next',
  PREV = 'prev',
}

interface IBattlepassProgressBarSizes {
  cardGap: number;
  cardWidth: number;
  start: number;
}

interface IUseDragSliderReturns {
  goTo: (direction: EUseDragSliderDirections) => void;
  move: (distance: number) => void;
  setRef: (newRef: HTMLDivElement) => void;
}

/**
 * Данные о таймере
 * @param { Ref<IBattlepassProgressBarSizes> } sizes - Реактивная переменная с размерами элементов слайдера
 * @returns { IUseDragSliderReturns } - методы для работы со слайдером
 */
export const useDragSlider = (sizes: Ref<IBattlepassProgressBarSizes>): IUseDragSliderReturns => {
  const sliderRef = ref<HTMLDivElement>();

  /* Метод для установки DOM элемента слайдера */
  const setRef = (newRef: HTMLDivElement) => {
    sliderRef.value = newRef;
  };

  /* Переместить слайдер на определенную дистанцию */
  const move = (distance: number) => {
    if (!sliderRef.value || !window) return;

    const scrollEnd = sliderRef.value.scrollWidth;
    const newScrollPosition = distance + sliderRef.value.scrollLeft;

    if (newScrollPosition < 0) {
      sliderRef.value.scrollLeft = 0;
      return;
    }

    if (newScrollPosition > scrollEnd) {
      sliderRef.value.scrollLeft = scrollEnd;
      return;
    }

    sliderRef.value.scrollLeft = newScrollPosition;
  };

  /* Метод для работы кнопок влево-вправо */
  const goTo = (direction: EUseDragSliderDirections) => {
    if (!sliderRef.value || !window) return;

    const directionCoeffient = direction === EUseDragSliderDirections.PREV ? -1 : 1;
    const distance = sizes.value.cardWidth + sizes.value.cardGap;

    sliderRef.value.scrollBy({
      behavior: 'smooth',
      left: directionCoeffient * distance,
    });
  };

  return { goTo, move, setRef };
};
