import frostivus23M from '~/assets/images/mainPage/sectionBanners/f23-m.jpg';
import frostivus23XL from '~/assets/images/mainPage/sectionBanners/f23-xl.jpg';
import PWBannerM from '~/assets/images/mainPage/sectionBanners/PWbanner-M-min.jpg';
import PWBannerXL from '~/assets/images/mainPage/sectionBanners/PWbanner-L-min.jpg';
import dragon24M from '~/assets/images/mainPage/sectionBanners/dragon-24-m.jpg';
import dragon24L from '~/assets/images/mainPage/sectionBanners/dragon-24-l.jpg';

/* Объект баннера ( варианты для мобилки/ПК ) */
interface IBannerImages {
  m: string;
  xl: string;
}

export const SECTIONS_BEFORE_DAILY = 2; // Сколько секций показать до дейли

export const banners: Record<number, IBannerImages> = {
  105: {
    m: frostivus23M,
    xl: frostivus23XL,
  },
  117: {
    m: PWBannerM,
    xl: PWBannerXL,
  },
  121: {
    m: dragon24M,
    xl: dragon24L,
  },
};
