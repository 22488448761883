<template>
  <section class="progress-bar">
    <div class="progress-bar__line">
      <div class="progress-bar__line-filled"></div>
    </div>
    <div class="progress-bar__points">
      <span class="progress-bar__points-item">0</span>
      <span class="progress-bar__points-item">{{ end }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IProgressBarProps } from './ProgressBar.types';

const props = defineProps<IProgressBarProps>();
const { current, end } = toRefs(props);

const {
  CSS: { proceedCssValue },
  Prices: { toFixedDefault },
} = GlobalUtils;

const styleVariables = computed(() => {
  const difference = +toFixedDefault((current.value / end.value) * 100);
  return {
    filledWidth: proceedCssValue(Math.min(100, difference), '%'),
  };
});
</script>

<style scoped lang="scss">
.progress-bar {
  --filled-width: v-bind('styleVariables.filledWidth');
}
</style>

<style scoped lang="scss" src="./ProgressBar.scss" />
