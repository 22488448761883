<template>
  <div class="battlepass-reward-block">
    <span class="battlepass-reward-block__reward">{{ $t('mainPage.battlepassRewardBlock.reward') }}</span>
    <span class="battlepass-reward-block__title">{{ rewardTitle }}</span>
    <div class="battlepass-reward-block__subtitle">
      <NuxtLinkLocale
        v-if="variant.type === EBattlepassRewardTypes.CASE"
        class="battlepass-reward-block__subtitle-link"
        :to="variant.link"
      >
        {{ variant.name }}
      </NuxtLinkLocale>
      <span v-else-if="variant.type === EBattlepassRewardTypes.MONEY" class="battlepass-reward-block__subtitle-money">
        {{ useFormatCurrency(variant.money) }}
      </span>
      <MainPageCoinSlot v-else :amount="formattedCoins" :icon="variant.icon" :color="variant.color" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { EBattlepassRewardTypes, type IBattlepassRewardProps } from './BattlepassRewardBlock.types';

const props = defineProps<IBattlepassRewardProps>();

const { t } = useI18n();

const rewardTitle = computed(() => {
  if (props.variant.type !== EBattlepassRewardTypes.CASE) return t('mainPage.battlepassRewardBlock.onBalance');

  const amount = props.variant.amount;
  const declination = t('mainPage.battlepassRewardBlock.opens', amount);
  return `${amount} ${declination}`;
});

const formattedCoins = computed(() => {
  if (props.variant.type !== EBattlepassRewardTypes.COINS) return '';
  return props.variant.coins.toLocaleString('ru-RU');
});
</script>

<style scoped lang="scss" src="./BattlepassRewardBlock.scss" />
