<template>
  <section class="battlepass-controller">
    <div class="battlepass-controller__points">
      <div class="battlepass-controller__points-row">
        <MainPageBattlepassEventPoint
          :img="battlepassStore.icons.coin"
          :title="$t('mainPage.battlepass.point1Title')"
          :color="eventStore.coinColor"
        >
          <template #description>
            <NuxtLinkLocale :to="newCases">{{ $t('mainPage.battlepass.point1Description') }}</NuxtLinkLocale>
          </template>
          <template #tooltip>
            <TooltipDescriptionCoin />
          </template>
        </MainPageBattlepassEventPoint>
        <ClientOnly>
          <MainPageBattlepassEventPoint
            v-if="battlepassLevelsStore.remainingRares"
            v-bind="battlepassLevelsStore.remainingRares"
          />
        </ClientOnly>
      </div>
      <div class="battlepass-controller__points-row">
        <ClientOnly>
          <MainPageBattlepassEventPoint
            v-if="battlepassLevelsStore.remainingUltra"
            v-bind="battlepassLevelsStore.remainingUltra"
          />
        </ClientOnly>
        <MainPageBattlepassEventPoint
          :img="battlepassStore.icons.case"
          :title="$t('mainPage.battlepass.point4Title')"
          :color="eventStore.coinColor"
        >
          <template #description>
            <I18nT keypath="mainPage.battlepass.point4Description">
              <template #link>
                <NuxtLinkLocale :to="ROUTING.MAINPAGE.TOP.path">{{
                  $t('mainPage.battlepass.eventTop')
                }}</NuxtLinkLocale>
              </template>
            </I18nT>
          </template>
        </MainPageBattlepassEventPoint>
      </div>
    </div>
    <section class="battlepass-controller__levels">
      <div class="battlepass-controller__levels-controls">
        <MainPageBattlepassNavigation
          class="battlepass-controller__levels-controls__prev"
          :is-prev="true"
          @click="cardsSlider.goTo(EUseDragSliderDirections.PREV)"
        />
        <MainPageBattlepassNavigation
          class="battlepass-controller__levels-controls__next"
          :is-prev="false"
          @click="cardsSlider.goTo(EUseDragSliderDirections.NEXT)"
        />
      </div>
      <section ref="battlepassLevels" class="battlepass-controller__levels-slider">
        <ClientOnly>
          <MainPageBattlepassProgressBar
            :color="battlepassStore.progressColor"
            :active-level="battlepassLevelsStore.activeLevel?.level ?? 0"
            :coin-data="{
              current: battlepassLevelsStore.formattedCurrentCoins,
              needed: battlepassLevelsStore.activeLevel?.cost ?? 0,
            }"
            :sizes="sizes"
            :items="battlepassStore.levels.length"
            :is-finished="battlepassLevelsStore.isFinished"
            @init="cardsSlider.move"
          />
        </ClientOnly>
        <MainPageBattlepassLevelCard
          v-for="level in battlepassStore.levels"
          :key="level.level"
          class="battlepass-controller__levels-slider__item"
          v-bind="level"
          :current-coins="battlepassLevelsStore.formattedCurrentCoins"
          :event-data="{
            button: eventStore.eventButtonGradient,
            color: eventStore.coinColor,
            icon: eventStore.coinIcon,
            gradient: eventStore.battlepassGradient,
            taskCoinIcon: battlepassStore.icons.coin,
          }"
          :claiming="battlepassStore.pendingLevels.has(level.level)"
          @claim="handleClaim(level.level)"
        />
      </section>
      <div v-if="!authStore.isAuth" class="battlepass-controller__levels-auth">
        <GeneralAuthButtonController :amplitude-source-of-click="EAuthButtonPlaceClicked.BP" />
      </div>
    </section>
    <section
      v-if="battlepassTasksStore.shownTasks.length && authStore.isAuth"
      ref="battlepassTasks"
      class="battlepass-controller__tasks"
    >
      <MainPageBattlepassTask
        v-for="task in battlepassTasksStore.shownTasks"
        :key="task.id"
        :task-id="task.id"
        v-bind="task"
        :event-data="{
          color: eventStore.coinColor,
          icon: eventStore.coinIcon,
          taskCoinIcon: battlepassStore.icons.coin,
        }"
      />
    </section>
  </section>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useFreeCasesStore } from '~/features/mainPage/store/freeCases.store';
import { useBattlepassStore } from '~/features/mainPage/store/battlepass.store';
import { useBattlepassLevelsStore } from '~/features/mainPage/store/battlepassLevels.store';
import { useBattlepassTasksStore } from '~/features/mainPage/store/battlepassTasks.store';
import { useRewardsStore } from '~/features/mainPage/store/rewards.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import { EUseDragSliderDirections, useDragSlider } from '~/features/mainPage/composables/useDragSlider';
import { useDragScroll } from '~/features/payment/composables/useDragScroll';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

const {
  redirects: { newCases },
} = useProjectSettings();

const authStore = useAuthStore();
const userStore = useUserStore();
const eventStore = useEventStore();
const freeCasesStore = useFreeCasesStore();
const battlepassStore = useBattlepassStore();
const battlepassLevelsStore = useBattlepassLevelsStore();
const battlepassTasksStore = useBattlepassTasksStore();
const rewardStore = useRewardsStore();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const battlepassLevels = ref<HTMLDivElement>();
useDragScroll(battlepassLevels);

const battlepassTasks = ref<HTMLDivElement>();
useDragScroll(battlepassTasks);

const sizes = computed(() => {
  return {
    cardGap: changeXL(16, 8),
    cardWidth: changeXL(200, 144),
    end: changeXL(40, 16),
    start: changeXL(90, 16),
  };
});

const cardsSlider = useDragSlider(sizes);

const handleClaim = async (level: number) => {
  if (!authStore.isAuth) return;

  const result = await battlepassStore.claimPrize(level);
  if (!result) return;

  rewardStore.viewedBattlepass.push(level);
  await Promise.allSettled([userStore.getMe(true), freeCasesStore.fetch()]);
};

watch(battlepassLevels, (elem) => {
  if (!elem) return;
  cardsSlider.setRef(elem);
});
</script>

<style scoped lang="scss" src="./BattlepassController.scss" />
