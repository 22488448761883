<template>
  <section class="daily-cases-controller">
    <p class="daily-cases-controller__title">{{ $t('mainPage.headerTabPresets.freeCases') }}</p>
    <div v-if="dailyCasesStore.userInfo.userId" class="daily-cases-controller__container">
      <MainPageDailyCasesAvailableCases :user-info="dailyCasesStore.userInfo" />

      <MainPageDailyCasesProfile
        :user-info="dailyCasesStore.userInfo"
        @on-generate-level-click="handlerGenerateLevelClick"
      />
    </div>
    <DailyCasesAuthWidgetController v-else />
  </section>
</template>

<script setup lang="ts">
import { useLevelGeneration } from '~/features/cases/composables/useLevelGeneration';
import DailyCasesAuthWidgetController from '~/features/cases/controllers/DailyCasesAuthWidgetController/DailyCasesAuthWidgetController.vue';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';

const { handlerGenerateLevelClick } = useLevelGeneration();
const dailyCasesStore = useDailyCasesStore();
</script>

<style scoped lang="scss" src="./DailyCasesController.scss" />
