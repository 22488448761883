<template>
  <div class="battlepass-progress-bar">
    <div class="battlepass-progress-bar__filled"></div>
  </div>
</template>

<script setup lang="ts">
import type { IBattlepassProgressBarEmits, IBattlepassProgressBarProps } from './BattlepassProgressBar.types';

const props = defineProps<IBattlepassProgressBarProps>();
const emits = defineEmits<IBattlepassProgressBarEmits>();

const passedWidth = computed(() => {
  if (props.activeLevel === 0) return props.sizes.start;

  const passedLevels = props.sizes.cardWidth * (props.activeLevel - 1);
  const levelGaps = props.sizes.cardGap * (props.activeLevel - 1);

  return props.sizes.start + passedLevels + levelGaps;
});

const width = computed(() => {
  if (props.isFinished) return '100%';
  if (props.activeLevel === 0 || props.coinData.needed === 0)
    return GlobalUtils.CSS.proceedCssValue(passedWidth.value, 'px');

  const activeLevelRelation = props.coinData.current / props.coinData.needed;
  const activeLevel = activeLevelRelation * props.sizes.cardWidth;

  return GlobalUtils.CSS.proceedCssValue(passedWidth.value + activeLevel, 'px');
});

const overallWidth = computed(() => {
  const cardsWidth = props.sizes.cardWidth * props.items;
  const cardsGap = props.sizes.cardGap * (props.items - 1);

  return GlobalUtils.CSS.proceedCssValue(props.sizes.start + cardsWidth + cardsGap + props.sizes.end, 'px');
});

const styleVariables = computed(() => ({
  progressBarColor: GlobalUtils.CSS.getBackgroundColor(props.color),
  progressBarOverallWidth: overallWidth.value,
  progressBarWidth: width.value,
}));

onMounted(() => {
  const distance = props.activeLevel === 0 ? 0 : passedWidth.value;
  emits('init', distance);
});
</script>

<style scoped lang="scss">
.battlepass-progress-bar {
  --progress-bar-color: v-bind('styleVariables.progressBarColor');
  --progress-bar-width: v-bind('styleVariables.progressBarWidth');
  --progress-bar-overall-width: v-bind('styleVariables.progressBarOverallWidth');
}
</style>

<style scoped lang="scss" src="./BattlepassProgressBar.scss" />
