<template>
  <div class="status-block">
    <MainPageCoinSlot
      v-if="variant.state === EBattlepassLevelState.CURRENT"
      class="status-block__coins"
      :icon="variant.icon"
      :color="variant.color"
      :amount="formattedCoins"
    />
    <div
      v-else-if="variant.state === EBattlepassLevelState.LOCKED || variant.state === EBattlepassLevelState.RECEIVED"
      class="status-block__level"
    >
      {{ variant.level }}
    </div>
    <SharedKitButton
      v-else
      :color-preset="EventButtonPreset(eventData.color, eventData.hover)"
      :size-preset="changeXL(SizePresets.L, SizePresets.S)"
      :type-preset="TypePresets.NONE"
      class="status-block__button"
      :text="$t('mainPage.claimPrize')"
      :is-loading="variant.claiming"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import type { IStatusBlockEmits, IStatusBlockProps } from './StatusBlock.types';
import { EventButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { Colors } from '~/constants/colors.constants';
import { EBattlepassLevelState } from '~/features/mainPage/Types/battlepass/client.types';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

const props = defineProps<IStatusBlockProps>();
const emits = defineEmits<IStatusBlockEmits>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const formattedCoins = computed(() => {
  if (props.variant.state !== EBattlepassLevelState.CURRENT) return '';

  const current = props.variant.currentCoins.toLocaleString('ru-RU');
  const needed = props.variant.neededCoins.toLocaleString('ru-RU');
  return `${current} / ${needed}`;
});

const styleVariables = computed(() => {
  let levelBackground = '';
  let levelText = '';

  if (props.variant.state === EBattlepassLevelState.DONE) {
    return {
      levelBackground,
      levelText,
    };
  }

  if (props.variant.state === EBattlepassLevelState.LOCKED) {
    levelBackground = `var(--battlepass-${props.variant.type}-level)`;
    levelText = Colors.NEUTRAL.WHITE;
  }

  if (props.variant.state === EBattlepassLevelState.RECEIVED) {
    levelBackground = Colors.NEUTRAL.BLACK;
    levelText = Colors.ADDITIONAL.GREEN;
  }

  return {
    levelBackground,
    levelText,
  };
});

const handleClick = () => {
  if (props.variant.state === EBattlepassLevelState.DONE) {
    MainPageEvents.rewardClaimed({ 'Event Level': props.variant.level, 'Reward Place': 'progress_bar' });
  }
  emits('claim');
};
</script>

<style scoped lang="scss">
.status-block {
  --level-background: v-bind('styleVariables.levelBackground');
  --level-text: v-bind('styleVariables.levelText');
}
</style>

<style scoped lang="scss" src="./StatusBlock.scss" />
