<template>
  <SkeletonComponentLoader>
    <section v-if="!eventsLoaded && !casesLoaded" class="all-cases-controller-skeleton">
      <SkeletonBaseBackground
        v-for="i in 9"
        :key="i"
        :width="sizes.container.width"
        :height="sizes.container.height"
        :border-radius="12"
        class="all-cases-controller-skeleton__background"
      >
        <SkeletonBaseForeground :width="sizes.image.width" :height="sizes.image.height" :border-radius="120" />
        <SkeletonBaseForeground :width="sizes.title.width" :height="sizes.title.height" :border-radius="4" />
        <SkeletonBaseForeground
          :width="sizes.description.width"
          :height="sizes.description.height"
          :border-radius="4"
        />
      </SkeletonBaseBackground>
    </section>
    <slot v-else></slot>
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useAllCasesStore } from '~/features/mainPage/store/allCases.store';

const eventStore = useEventStore();
const allCasesStore = useAllCasesStore();

const { isLoaded: eventsLoaded } = storeToRefs(eventStore);
const { isLoaded: casesLoaded } = storeToRefs(allCasesStore);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const sizes = computed(() => ({
  container: {
    height: changeXL(325, 208),
    width: changeXL(240, 168),
  },
  description: {
    height: changeXL(20, 8),
    width: changeXL(184, 136),
  },
  image: {
    height: changeXL(224, 152),
    width: changeXL(224, 152),
  },
  title: {
    height: changeXL(20, 8),
    width: changeXL(96, 80),
  },
}));
</script>

<style scoped lang="scss" src="./AllCasesController.skeleton.scss" />
