<template>
  <div class="adv__container">
    <div class="adv__bg">
      <NuxtImg
        v-if="imageSrc"
        provider="localProvider"
        :width="bgSizes.width"
        :height="bgSizes.height"
        :src="imageSrc"
        loading="lazy"
      />
    </div>
    <div class="adv__content">
      <p class="adv__text">{{ $t('battlesTitle') }}</p>
      <SharedKitButton
        class="adv__btn"
        :color-preset="BattleColorPresets.MAIN"
        :size-preset="changeXL(SizePresets.L, SizePresets.S)"
        :type-preset="TypePresets.ROUND"
        :text="$t('mainPage.battlesAdv.button')"
        :link-props="{
          to: ROUTING.BATTLES.MAIN.path,
        }"
        @click="MainPageEvents.battleBannerClicked({ 'Button Place': 'banner_button' })"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { BattleColorPresets } from '~/features/battles/presets/SharedKitButton.presets';
import bannerM from 'assets/images/mainPage/banner-battles-m.png';
import bannerXL from 'assets/images/mainPage/banner-battles-xl.png';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const bgSizes = computed(() => {
  return changeXL({ height: 160, width: 1264 }, { height: 88, width: 768 });
});

const imageSrc = computed(() => {
  return changeXL(bannerXL, changeMD(bannerXL, bannerM));
});
</script>
<style lang="scss" src="./BattlesAdvController.scss" scoped></style>
