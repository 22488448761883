import type { EBattlepassLevelState } from '~/features/mainPage/Types/battlepass/client.types';

export enum EImageTypes {
  CASE = 'case',
  COINS = 'coins',
  MONEY = 'money',
}

interface IImageBlockBalanceCoins {
  icon: string;
  type: EImageTypes.COINS;
}

interface IImageBlockBalanceMoney {
  type: EImageTypes.MONEY;
}

interface IImageBlockCase {
  amount: number;
  img: string;
  type: EImageTypes.CASE;
}

export type TImageBlockVariants = IImageBlockBalanceCoins | IImageBlockBalanceMoney | IImageBlockCase;

export interface IImageBlockProps {
  state: EBattlepassLevelState;
  variant: TImageBlockVariants;
}
