<template>
  <div class="battlepass-task">
    <div class="battlepass-task__info">
      <div class="battlepass-task__info-container">
        <span class="battlepass-task__info-container__title">{{ $t('mainPage.battlepassTask.title') }}</span>
        <span class="battlepass-task__info-container__description">{{ description }}</span>
        <NuxtLinkLocale
          v-if="objectiveData"
          class="battlepass-task__info-container__link"
          :to="objectiveData.link"
          external
        >
          {{ objectiveData.text }}
        </NuxtLinkLocale>
        <MainPageBattlepassTaskProgressBar
          v-else-if="progressData"
          class="battlepass-task__info-container__progress-bar"
          :current="progressData.current"
          :end="progressData.end"
        />
      </div>
      <SharedKitButton
        class="battlepass-task__info-button"
        :color-preset="ColorPresets.PRIMARY"
        :size-preset="changeXL(SizePresets.L, SizePresets.S)"
        :type-preset="TypePresets.RECTANGLE"
        :link-props="{
          to: taskLink,
          external: true,
        }"
        :text="$t('mainPage.battlepassTask.proceed')"
        @click="MainPageEvents.taskCompleted({ ID: taskId })"
      />
    </div>
    <div class="battlepass-task__reward">
      <NuxtImg
        v-if="image"
        provider="localProvider"
        :src="image"
        :width="changeXL(82, 52)"
        :height="changeXL(82, 52)"
        loading="lazy"
      />
      <MainPageBattlepassRewardBlock v-if="infoBlockVariant" :variant="infoBlockVariant" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  EBattlepassRewardTypes,
  type TBattlepassRewardVariants,
} from '../BattlepassRewardBlock/BattlepassRewardBlock.types';
import type { IBattlepassTaskProps } from './BattlepassTask.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import type { TPossibleNull } from '~/types/Shared.types';
import eventRubleImage from 'assets/images/mainPage/event-ruble.png';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

const props = defineProps<IBattlepassTaskProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const image = computed(() => {
  if (props.reward.case) return props.reward.case.img;
  if (props.reward.coins) return props.eventData.taskCoinIcon;
  return eventRubleImage;
});

const infoBlockVariant = computed<TPossibleNull<TBattlepassRewardVariants>>(() => {
  if (props.reward.case) {
    return {
      amount: props.reward.case.amount,
      link: props.reward.case.link,
      name: props.reward.case.name,
      type: EBattlepassRewardTypes.CASE,
    };
  }

  if (props.reward.coins) {
    return {
      coins: props.reward.coins,
      color: props.eventData.color,
      icon: props.eventData.icon,
      type: EBattlepassRewardTypes.COINS,
    };
  }

  if (props.reward.money) {
    return {
      money: props.reward.money,
      type: EBattlepassRewardTypes.MONEY,
    };
  }

  return null;
});
</script>

<style scoped lang="scss" src="./BattlepassTask.scss" />
