import type { TBackgroundColor } from '~/types/Shared.types';

export enum EBattlepassRewardTypes {
  CASE = 'case',
  COINS = 'coins',
  MONEY = 'money',
}

interface IBattlepassRewardBalanceCoins {
  coins: number;
  color: TBackgroundColor;
  icon: string;
  type: EBattlepassRewardTypes.COINS;
}

interface IBattlepassRewardBalanceMoney {
  money: number;
  type: EBattlepassRewardTypes.MONEY;
}

interface IBattlepassRewardCase {
  amount: number;
  link: string;
  name: string;
  type: EBattlepassRewardTypes.CASE;
}

export type TBattlepassRewardVariants =
  | IBattlepassRewardBalanceCoins
  | IBattlepassRewardBalanceMoney
  | IBattlepassRewardCase;

export interface IBattlepassRewardProps {
  variant: TBattlepassRewardVariants;
}
